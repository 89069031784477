<template>
  <div class="home">
      <Header></Header>
        <div class="all-message-main-box">
          <div class="product01-box">
            <div class="top-tit-box">
              <a  class="a1 Gotham-Book lantingheijian" @click.prevent="goAllMsg">意见反馈</a>
              <span class="sp">|</span>
              <a href="" class="a2 Gotham-Book lantingheijian">常见问题</a>
            </div>
            <div class="top-img">
              <img v-if="bannerdata" :src="bannerdata" alt="">
            </div>
            <div class="select-box">
              <div class="left-line"></div>
              <div class="select-div">
                          <div class="select-logo">
                  <img src="../assets/img/all-message-logo.png" alt="">
                </div>
                <div class="right-input">
                  <input type="text Gotham-Book lantingheijian" v-model="searchText" @keyup.enter="getSelectMsg" placeholder="请输入问题，如“如何开启超广视野”" class="input">
                </div>
              </div>
              <div class="rigth-line"></div>
            </div>
            <div class="bottom-content">
              <div class="left-slide" v-if="leftNavList">
                <div @click="clickLeftNav(index,item.code,item.value)" :class="[showIndex==index?'slide slide-active Gotham-Book lantingheijian' :'slide Gotham-Book lantingheijian']" v-for="(item,index) in navigators" :key="index">
                  {{item.name}}
                </div>
                <div class="slide slide2 Gotham-Book lantingheijian" @click="showAll" v-if="allNavigators.length>5">
                     查看更多
                     <span class="sp">
                       <img src="../assets/img/xiala-logo.png" alt="">
                     </span>
                </div>
              </div>
              <div class="right-content">
                <div class="right-slide" v-if="allMsg.length>0" style="display: block;">
                  <div class="right-top-tit">
                    <p class="p1 Gotham-Book lantingheijian">序号</p>
                    <p class="p2 Gotham-Book lantingheijian">关键词</p>
                    <p class="p3 Gotham-Book lantingheijian">热度</p>
                  </div>
                  <div class="right-slide-content">
                    <div class="slide-p" v-for="(item,index) in allMsg" :key="index" @click="goDetails(item.id)">
                      <p class="num Gotham-Book lantingheijian">{{index+1}}</p>
                      <p class="tit Gotham-Book lantingheijian">{{item.name}}</p>
                      <p class="logo">
                          <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='热'" src="../assets/img/hot.png" alt="">
                          <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='新'" src="../assets/img/new.png" alt="">
                          <img v-show="item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO&&item.metadataInfo.CUSTOME_PORTAL_RECOMMENDED_LOGO[0].title=='沸'" src="../assets/img/fei.png" alt="">
                      </p>
                    </div>
                  </div>
                </div>
                <div class="right-slide" v-else-if="allMsg.length<1&&!leftNavListStatus" style="display: block;">
                  <img class="no-msg" src="../assets/img/loading.png" alt="">
                </div>
                <div class="right-slide" v-else style="display: block;">
                  <img class="no-msg" src="../assets/img/zanwushuju.png" alt="">
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- <div data-track-click={stringify({ portal_id: 2 })} data-track-bubble="true" data-track-click-code="global-click">
        点击后自动上报
      </div> -->
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios';
import Header from "../components/Header"
import Footer from "../components/Footer"



export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      //所有新闻
      allMsg:[],
      //首页banner
      bannerdata:"",
      //最新消息列表
      newMsg:[],
      //更新信息banner
      updatedata:[
        {coverPicture:''}
      ],
      rightMoreArr:[],
      bannerUrl:'',
      pageNum:1,
      pageSize:20,
      searchText:'',
      leftNavList:[],
      leftNavListStatus:false,
      showIndex:0,
      //左侧导航code
      leftCode:'',
      leftValue:"",
      navigators:[],
      allNavigators:[],
    };
  },
  beforeMount: function () {
    // this.getBaseInfo2()
    //获取链接参数
    var query = this.$route.query;
    //获取搜索参数
    this.searchText=query.searchText;
    this.getBaseInfo(interfaces.blockIdArr.opinionarticle)
    //获取banner图片
    this.getBannerImg()
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
     
      //点击左侧导航
      clickLeftNav(index,code,value){
          this.showIndex=index;
          this.leftCode=code
          this.leftValue=value;
          this.getSelectMsg()
      },
        //获取导航
   getBaseInfo(id){
        var $this = this;
        var config = {
          method: "get",
          url: `${interfaces.baseUrl}/portal/open/block/public/zui/getBaseInfo/${id}`,
          headers:interfaces.headers(),
        };
  
       axios(config)
          .then(function (response) {
            $this.leftNavList=response.data.result;
            // console.log($this.leftNavList.navigators[0].code)
            if(response.data.result.navigators){
              $this.allNavigators=response.data.result.navigators;
              $this.navigators=$this.leftNavList.navigators.filter((item,index)=>{
                return index<5
              })
              //  console.log($this.leftNavList.navigators);
                $this.leftCode=$this.leftNavList.navigators[0].code;
                $this.leftValue=$this.leftNavList.navigators[0].value
                //获取当前信息
                $this.getSelectMsg()
            }
          })
          .catch(function (error) {
            return error
          });
    },
    getSelectMsg(code,value){
        var $this=this
        if($this.searchText!=null&&$this.searchText!=undefined&&$this.searchText!=''){
            //获取搜索结果文章
            $this.searchFn({
                "blockId": interfaces.blockIdArr.opinionarticle,
                "pageNum":$this.pageNum,
                "pageSize":$this.pageSize,
                "searchText":$this.searchText,
                "filterConditionList":[
                    {
                        "type":3,
                        "code":$this.leftCode,
                        "value":[$this.leftValue]
                    }
                ]
            })
            }else{
                this.searchFn({
                    "blockId": interfaces.blockIdArr.opinionarticle,
                    "pageNum":this.pageNum,
                    "pageSize":this.pageSize,
                    "searchText":"",
                    "filterConditionList":[
                        {
                        "type":3,
                        "code":$this.leftCode,
                        "value":[$this.leftValue]
                    }
                    ]
                })
            }
    },
      getBannerImg(){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data:{
                "blockId": interfaces.blockIdArr.opinionBanner,
                "pageNum":1,
                "pageSize":1,
                "searchText":"",
                "filterConditionList":[] 
            },
            };
    
           axios(config)
            .then(function (response) {
              if(response.data.result.records.length>0){
                  $this.bannerdata=response.data.result.records[0].coverPicture
              }
               
                // console.log(response.data.result.records)
            })
            .catch(function (error) {
                return error
            });
      },
      //获取右侧内容
        searchMoreFn(data){
            var $this = this;
            var config = {
                method: "post",
                url: `${interfaces.baseUrl}/portal/open/block/search`,
                headers:interfaces.postHeaders(),
                data,
                };
        
            axios(config)
                .then(function (response) {
                       $this.rightMoreArr=response.data.result;
                })
                .catch(function (error) {
                    return error
                });
        },
        //搜索内容区块中内容
    searchFn(data){
        var $this = this;
        var config = {
            method: "post",
            url: `${interfaces.baseUrl}/portal/open/block/search`,
            headers:interfaces.postHeaders(),
            data,
            };
    
           axios(config)
            .then(function (response) {
                $this.allMsg=response.data.result.records;
                $this.leftNavListStatus=true
                // console.log($this.allMsg)
            })
            .catch(function (error) {
                return error
            });
      },
        //获取内容详情
        getContent(data){
          var $this = this;
          // console.log(this)
          var config = {
              method: "post",
              url: `${interfaces.baseUrl}/portal/open/block/getContent`,
              headers:interfaces.postHeaders(),
              data,
            };
            axios(config)
              .then(function (response) {
                  //console.log(response.data);
                  return response
              })
              .catch(function (error) {
                  return error
              });
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            return Y+M+D;
        },
        goDetails(id){
          this.$router.push({
            name: 'newsmsg',
            query:{
              id:id
            }
          })
        },
         goAllMsg(){
          this.$router.push({
            name: 'feedback',
          })
        },
        showAll(){
          this.navigators=this.allNavigators;
          this.allNavigators=[];
        },
  },
};
</script>
<style lang="less" scoped> 
.footer-box{
	background: #ffffff !important;
}
.all-message-main-box{
	width: 100%;
	min-height: calc(100vh - 3rem);
	overflow: hidden;
	background: #f5f5f6;
  position: relative;
  padding-top: 1.75rem;
  box-sizing: border-box;
}
.all-message-main-box img{
	display: block;
	width: 100%;
}
.all-message-main-box .product01-box{
	width: 1200px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}
.all-message-main-box .product01-box .top-tit-box{
	width: 100%;
	margin-top:30px;
	font-size:14px;
    overflow: hidden;
    text-align: left;
}
.all-message-main-box .product01-box .top-tit-box .a1{
  color: #c7c7c7;
  cursor: pointer;
}
.all-message-main-box .product01-box .top-tit-box .a1:hover{
  	color: #0d0c22;
}
.all-message-main-box .product01-box .top-tit-box .sp{
	margin-left: 7.5px;
	color: #c7c7c7;
}
.all-message-main-box .product01-box .top-tit-box .a2{
	color: #0d0c22;
	margin-left: 7.5px;
}
.all-message-main-box .product01-box .top-img{
	display: block;
	overflow: hidden;
	margin-top:7.5px;
	width: 100%;
}
.all-message-main-box .product01-box .select-box{
	width: 100%;
	position: relative;
	height:46px;
	line-height:46px;
	margin-top: 45px;
}
.all-message-main-box .product01-box .select-box .left-line{
	position: absolute;
	top: 50%;
	transform: translate(0,-50%);
	width:217.5px;
	left: 0;
	height: 1px;
	background: #bfbfbf;
}
.all-message-main-box .product01-box .select-box .rigth-line{
	position: absolute;
	top: 50%;
	transform: translate(0,-50%);
  width:217.5px;
	right: 0;
	height: 1px;
	background: #bfbfbf;
}
.all-message-main-box .product01-box .select-box .select-div{
	width:700px;
	margin: 0 auto;
	height: 46px;
	line-height: 46px;
	background: #ffffff;
	border-radius: 10px;
	border:1px solid #dfe1e5;
}
.all-message-main-box .product01-box .select-box .select-div .select-logo{
	float: left;
	width:18.4px;
	margin-top: 11.6px;
	margin-left: 12px;
}
.all-message-main-box .product01-box .select-box .select-div input{
	background: none;
	margin-left: 12px;
	color: #000000;
	font-size:16px;
	line-height:5.6px;
	width: 400px;
}
.all-message-main-box .product01-box .select-box .select-div input::-webkit-input-placeholder {
    color:#dfe1e5;
}
.all-message-main-box .product01-box .select-box .select-div input::-moz-placeholder { 
    color: #dfe1e5;
}
.all-message-main-box .product01-box .select-box .select-div input:-ms-input-placeholder {
    color: #dfe1e5;
}
.all-message-main-box .product01-box .select-box .select-div input:-moz-placeholder {
    color: #dfe1e5;
}
.bottom-content{
	display: block;
	width: 100%;
	overflow: hidden;
	border: 1px solid #dfe1e5;
	border-radius: 12px;
  margin-top:24px;
  box-sizing: border-box;
  display: flex;
}
.bottom-content .left-slide{
	width:190px;
	/* height: 100%; */
	background: #f2f2f5;
	float: left;
  padding-top:32px;
  padding-bottom: 32px;
}
.bottom-content .left-slide .slide{
	width: 100%;
	height:34px;
	text-align: center;
	color: #0d0c22;
	font-size:15px;
	line-height:34px;
}
.bottom-content .left-slide .slide2{
	width: 100%;
	height:34px;
	text-align: center;
	color: #686868;
	font-size:15px;
  line-height:34px;
  background: #e0e0e1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-content .left-slide .slide2 .sp{
  width:15px;
  margin-left: 3px;
}
.bottom-content .left-slide .slide-active{
	color:#0d0c22;
	background: #ffffff;
}
.bottom-content .right-content{
	float: left;
	width:1008px;
	background: #ffffff;
	overflow: hidden;
}
.bottom-content .right-content .right-slide{
	width: 100%;
	overflow: hidden;
	padding: 0 23.8px;
	box-sizing: border-box;
	display: none;
}
.bottom-content .right-content .right-slide .right-top-tit{
	width: 100%;
	margin-top:40px;
	font-size:14px;
	color: #999999;
	padding-bottom:12px;
	border-bottom: 1px solid #f2f2f5;
	overflow: hidden;
}
.bottom-content .right-content .right-slide .right-top-tit .p1{
	width:96px;
	text-align: center;
  float: left;
}
.bottom-content .right-content .right-slide .right-top-tit .p2{
	width:604px;
  float: left;
  text-align: left;
}
.bottom-content .right-content .right-slide .right-top-tit .p3{
	width:240px;
	text-align: center;
  float: left;
  text-align: left;
}
.bottom-content .right-content .right-slide .right-slide-content{
	width: 100%;
	overflow: hidden;
}
.bottom-content .right-content .right-slide .right-slide-content .slide-p{
	width: 100%;
	overflow: hidden;
	height:48px;
	line-height:48px;
	border-bottom: 1.6px solid #f2f2f5;
	font-size: 14px;
	display: block;
	cursor: pointer;
}
.bottom-content .right-content .right-slide .right-slide-content .slide-p .num{
	width:96px;
	text-align: center;
	float: left;
	color: #f46d5f;
}
.bottom-content .right-content .right-slide .right-slide-content .slide-p .tit{
	width:604px;
	float: left;
  color: #0078b6;
  text-align: left;
}
.bottom-content .right-content .right-slide .right-slide-content .slide-p .logo{
	float: left;
	width:16.4px;
	margin-top: 12.8px;
}
.no-msg{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg img{
  width: 100%;
}
</style>